import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import { StyledRightContainer } from "pages/workInProgress/styles";
import ProjectChartComponent from "./projectChartComponent";

const BottomComponent = ({ data, eventData }) => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const [estimatedRevenue, setEstimatedRevenue] = useState(0);
  const [earnedRevenue, setEarnedRevenue] = useState(0);
  const [billingsToDate, setBillingsToDate] = useState(0);
  const [estimatedGrossProfit, setEstimatedGrossProfit] = useState(0);
  const [estimatedCostOfRevenue, setEstimatedCostOfRevenue] = useState(0);
  const [costOfRevenue, setCostOfRevenue] = useState(0);
  const [costIncurredToDate, setCostIncurredToDate] = useState(0);
  const [grossProfitToDate, setGrossProfitToDate] = useState(0);
  const [costToComplete, setCostToComplete] = useState(0);
  const [billingInExcessOfCosts, setBillingInExcessOfCosts] = useState(0);
  const [diffInEarningAndBillings, setDiffInEarningAndBillings] = useState(0);
  const [currentPaymentDue, setCurrentPaymentDue] = useState(0);
  const [contractCompletionDate, setContractCompletionDate] = useState("");
  const [previousObject, setPreviousObject] = useState({});
  const [currentYearContractCosts, setCurrentYearContractCosts] = useState(
    Math.round(parseFloat(data?.current_year_contract_costs)) || 0
  );
  const [currentYearEarnedRevenue, setCurrentYearEarnedRevenue] = useState(
    Math.round(parseFloat(data?.current_year_earned_revenue)) || 0
  );
  const [currentYearGrossProfit, setCurrentYearGrossProfit] = useState(0);
  const [comparisonResults, setComparisonResults] = useState({});

  useEffect(() => {
    // calculate total Estimated revenue with change order value
    let totalChangeOrderValue = data?.co_data?.reduce((total, item) => {
      // Convert the values to numbers and add them to the total
      let changeInContractValue = item?.change_in_contract_value
        ? parseFloat(item?.change_in_contract_value)
        : 0;
      return total + changeInContractValue;
    }, 0);
    const totalEstimatedRevenue =
      parseFloat(eventData?.project_value_or_estimated_revenue) ||
      parseFloat(data?.value);

    // calculate total Estimated cost of revenue with change in cost from change orders
    let changeInContractCost = data?.co_data?.reduce((totalChange, coData) => {
      // Sum up all estimated_cost_for_the_change in co_data array
      return totalChange + parseFloat(coData?.estimated_cost_for_the_change);
    }, 0);
    const totalEstimatedCostOfRevenue =
      parseFloat(eventData?.estimated_cost_of_revenue) ||
      parseFloat(data?.original_estimated_cost_of_revenue);
    setCostIncurredToDate(
      parseFloat(eventData?.ppc_data?.cost_incurred_to_date)
    );
    setEstimatedRevenue(Math.round(totalEstimatedRevenue));
    setEstimatedCostOfRevenue(totalEstimatedCostOfRevenue);

    setContractCompletionDate(
      eventData?.contract_completion_date
        ? eventData?.contract_completion_date
        : data?.substantially_completed_date
    );
    setBillingsToDate(
      Math.round(parseFloat(eventData?.ppc_data?.billings_to_date))
    );
    setCurrentYearContractCosts(
      Math.round(parseFloat(eventData?.current_year_contract_costs)) || 0
    );
    setCurrentYearEarnedRevenue(
      Math.round(parseFloat(eventData?.current_year_earned_revenue)) || 0
    );
    setPreviousObject(getPreviousObject());
  }, [data, eventData]);
  useEffect(() => {
    compareValues();
  }, [eventData, previousObject]);
  const compareValues = () => {
    if (!previousObject) return;

    // Calculate values for the previous data object
    const previousEstimatedCostOfRevenue = parseFloat(
      previousObject?.estimated_cost_of_revenue
    );
    const previousCurrentYearEarnedRevenue =
      Math.round(parseFloat(previousObject?.current_year_earned_revenue)) || 0;
    const previousTotalEstimatedRevenue = Math.round(
      parseFloat(previousObject?.project_value_or_estimated_revenue || 0)
    );
    const previousCostIncurredToDate = parseFloat(
      previousObject?.ppc_data?.cost_incurred_to_date
    );
    const previousCostToComplete =
      previousEstimatedCostOfRevenue - previousCostIncurredToDate;
    const previousTotalOfEarnedRevenue =
      previousEstimatedCostOfRevenue > 0 && previousCostIncurredToDate > 0
        ? previousTotalEstimatedRevenue *
          (previousCostIncurredToDate / previousEstimatedCostOfRevenue)
        : 0;
    const previousCostOfRevenue =
      previousEstimatedCostOfRevenue > 0 && previousCostIncurredToDate > 0
        ? previousEstimatedCostOfRevenue *
          (previousCostIncurredToDate / previousEstimatedCostOfRevenue)
        : 0;
    const previousGrossProfitToDate =
      Math.round(previousTotalOfEarnedRevenue) -
      Math.round(previousCostOfRevenue);
    const previousEstimatedGrossProfit =
      previousTotalEstimatedRevenue - previousEstimatedCostOfRevenue;

    const CurrentCostToComplete =
      parseFloat(eventData?.estimated_cost_of_revenue) -
      parseFloat(eventData?.ppc_data?.cost_incurred_to_date);

    let TotalCostOfRevenue =
      parseFloat(eventData?.estimated_cost_of_revenue) > 0 &&
      parseFloat(eventData?.ppc_data?.cost_incurred_to_date) > 0
        ? parseFloat(eventData?.estimated_cost_of_revenue) *
          (parseFloat(eventData?.ppc_data?.cost_incurred_to_date) /
            parseFloat(eventData?.estimated_cost_of_revenue))
        : 0;
    let totalOfEarnedRevenue =
      parseFloat(eventData?.estimated_cost_of_revenue) > 0 &&
      parseFloat(eventData?.ppc_data?.cost_incurred_to_date) > 0
        ? parseFloat(eventData?.project_value_or_estimated_revenue) *
          (parseFloat(eventData?.ppc_data?.cost_incurred_to_date) /
            parseFloat(eventData?.estimated_cost_of_revenue))
        : 0;

    // Compare and store the results
    setComparisonResults({
      estimatedCostOfRevenue:
        parseFloat(eventData?.estimated_cost_of_revenue) >
        previousEstimatedCostOfRevenue,
      asOfDate:
        new Date(eventData?.as_of_date).getTime() <
        new Date(previousObject?.as_of_date).getTime(),
      currentYearEarnedRevenue:
        Math.round(parseFloat(eventData?.current_year_earned_revenue)) <
        Math.round(parseFloat(previousObject?.current_year_earned_revenue)),
      costToComplete: CurrentCostToComplete > previousCostToComplete,
      grossProfitToDate:
        totalOfEarnedRevenue - TotalCostOfRevenue < previousGrossProfitToDate,
      estimatedGrossProfit:
        Math.round(parseFloat(eventData?.project_value_or_estimated_revenue)) -
          parseFloat(eventData?.estimated_cost_of_revenue) <
        previousEstimatedGrossProfit,
    });
  };

  const getPreviousObject = () => {
    const wipData = [...data?.wip_data];
    const selectedIndex = wipData?.findIndex((obj) => obj.id === eventData?.id);

    // Initialize the previous object
    let previousObject = null;

    // If there is a previous object, retrieve it
    if (selectedIndex > 0) {
      previousObject = wipData[selectedIndex - 1];
    }
    return previousObject;
  };
  useEffect(() => {
    setCurrentYearGrossProfit(
      currentYearEarnedRevenue - currentYearContractCosts
    );
  }, [currentYearContractCosts, currentYearEarnedRevenue]);
  useEffect(() => {
    //calculate billing and Earning in excess of cost
    const diff = earnedRevenue - billingsToDate;
    setDiffInEarningAndBillings(diff);
    if (diff > 0) {
      setBillingInExcessOfCosts(diff);
    }
    if (diff < 0) {
      setBillingInExcessOfCosts(billingsToDate - earnedRevenue);
    }
  }, [billingsToDate, earnedRevenue]);

  useEffect(() => {
    const current_payment_due =
      billingsToDate -
      parseFloat(
        eventData?.ppc_data?.previous_progress_payment
          ? eventData?.ppc_data?.previous_progress_payment
          : 0
      ) -
      parseFloat(eventData?.ppc_data?.holdback_or_retainage_to_date);

    setCurrentPaymentDue(current_payment_due);
  }, [billingsToDate]);

  useEffect(() => {
    // calculate total earned revenue
    let totalOfEarnedRevenue =
      estimatedCostOfRevenue > 0 && costIncurredToDate > 0
        ? estimatedRevenue * (costIncurredToDate / estimatedCostOfRevenue)
        : 0;

    setEarnedRevenue(Math.round(totalOfEarnedRevenue));
    setCostToComplete(estimatedCostOfRevenue - costIncurredToDate);
  }, [estimatedRevenue, estimatedCostOfRevenue, costIncurredToDate]);
  useEffect(() => {
    // calculate total cost of revenue
    let TotalCostOfRevenue =
      estimatedCostOfRevenue > 0 && costIncurredToDate > 0
        ? estimatedCostOfRevenue * (costIncurredToDate / estimatedCostOfRevenue)
        : 0;
    setCostOfRevenue(Math.round(TotalCostOfRevenue));
  }, [estimatedCostOfRevenue, costIncurredToDate]);

  useEffect(() => {
    setEstimatedGrossProfit(estimatedRevenue - estimatedCostOfRevenue);
  }, [estimatedRevenue, estimatedCostOfRevenue]);
  useEffect(() => {
    setGrossProfitToDate(earnedRevenue - costOfRevenue);
  }, [earnedRevenue, costOfRevenue]);

  return (
    <StyledRightContainer style={{ width: "100%", height: "100%" }}>
      <ProjectChartComponent
        title="Earned Revenue"
        subTitle1="Estimated Revenue"
        subTitle2="Earned Revenue"
        subTitle3="Estimated Gross Profit"
        subTitle4="Gross Profit to Date"
        subTitle5="Current Year Gross Profit"
        value1={estimatedRevenue}
        value2={earnedRevenue}
        value3={estimatedGrossProfit}
        value4={grossProfitToDate}
        value5={currentYearGrossProfit}
        redfleg3={comparisonResults?.estimatedGrossProfit}
        redfleg4={comparisonResults?.grossProfitToDate}
        text3="Value is lower than last event"
        text4="Value is lower than last event"
      />
      <ProjectChartComponent
        title="Cost to Date"
        subTitle1="Estimated Cost of Revenue"
        subTitle2="Cost Incurred to Date"
        subTitle3="Actual Cost of Revenue"
        subTitle4="Total Cost to Complete"
        subTitle5="Current Year Contract Costs"
        value1={estimatedCostOfRevenue}
        value2={costIncurredToDate}
        value3={parseFloat(data?.original_estimated_cost_of_revenue)}
        value4={costToComplete}
        value5={currentYearContractCosts}
        redfleg1={comparisonResults?.estimatedCostOfRevenue}
        redfleg4={comparisonResults?.costToComplete}
        text1="Value is higher than last event"
        text4="Value is higher than last event"
      />
      <ProjectChartComponent
        title="Billings to Date"
        subTitle1="Estimated Revenue"
        subTitle2="Billings to Date"
        subTitle3={
          diffInEarningAndBillings < 0 || data?.project_status === "Completed"
            ? "Billings in Excess of Costs"
            : "Costs in Excess of Billings"
        }
        subTitle4="Current Payment Due"
        subTitle5="Current Year Earned Revenue"
        value1={estimatedRevenue}
        value2={billingsToDate}
        value3={
          data?.project_status === "Completed" ? 0 : billingInExcessOfCosts
        }
        value4={currentPaymentDue}
        value5={currentYearEarnedRevenue}
        redfleg5={comparisonResults?.currentYearEarnedRevenue}
        text5="Value is lower than last event"
      />
      <ProjectChartComponent
        title="Time Lapsed"
        marginRight="0"
        subTitle1="Planned Start Date"
        subTitle2="Actual Start Date"
        subTitle3="Scheduled Completion Date"
        subTitle4="Contract Completion Date"
        subTitle5="Reporting Date"
        date={true}
        status={data?.project_status}
        value1={data?.planned_start_date}
        value2={data?.actual_start_date}
        value3={data?.substantially_completed_date}
        value4={contractCompletionDate}
        value5={eventData?.as_of_date}
        redfleg5={comparisonResults?.asOfDate}
        text5="Date is older than last event"
      />
    </StyledRightContainer>
  );
};

export default BottomComponent;

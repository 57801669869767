import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "constants.js";
import Typography from "@material-ui/core/Typography";
import Tab from '@mui/material/Tab';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TabList from '@mui/lab/TabList';


export const TitleDiv = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center; 
  width: 100%;
  margin: 16px 0 0;
`;



export const StyledTabList = styled(TabList)`
  && {
    color: #DAEDD7;
    width: 100%;
    margin-left: 25px;
    // background-color: white; // Set background to white
  }

  // To hide the indicator by default, and will be shown on selected tab
  .MuiTabs-indicator {
    // background-color: ${COLORS.primaryOrange}; // Orange underline for selected tab
  }
`;

// StyledTab with no border and selected tab underline in orange
export const StyledTab = styled(Tab)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    color: ${COLORS.blurOutText};
    padding-right: 2px;
    padding-left: 2px;
    // background: white; // White background for all tabs
    border-radius: 5px 5px 0px 0px;
    transition: all 0.1s;
    cursor: pointer;
    user-select: none;
    border: none; // Remove any border around the tabs
  }

  &:hover {
    color: ${COLORS.primaryOrange};
  }

  &&.Mui-selected {
    color: ${COLORS.primaryOrange};
    font-size: 16px;
    // background: white; 
    font-weight: 500;
    border-bottom: 2px solid ${COLORS.primaryOrange}; // Orange underline for the selected tab
  }
`;

export const SetupContainer = styled.div`
  padding-bottom: 32px;
  margin-left: 0px;
  z-index=0;
`;


export const StyledTooltip = styled(Tooltip)``;

export const StyledUploadIcon = styled.label`
  max-width:  ${props => props.width || '200px'};
  width: 55%;
  height: ${props => props.height || '45px'};
  margin-right: ${props => props.marginRight || '0px'};
  margin-left: ${props => props.marginLeft || '0px'};
  margin-top: ${props => props.marginTop || '0px'};
  margin-bottom: ${props => props.marginBottom || '0px'};
  background-color: ${props => props.isReversedColor? 'white' : `${props.borderColor || "#FF6D1D"}` };
  border: 1px solid ${props => props.borderColor || "#FF6D1D"};
  box-sizing: border-box;
  border-radius: 6px;
  ${props => props.hasBoxShadow ? "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" : ""}
  color: ${props => props.isReversedColor? `${props.borderColor || "#FF6D1D"}`  : 'white'};

  font-size: ${props => props.fontSize || '17px'};
  font-weight: ${props => props.weight || '600'};
  transition: all 0.5s ease;
  text-transform: capitalize;
  text-align: center;
  line-height: 22px;
  padding-top: 10px;

  &:hover {
      background-color: ${props => props.isReversedColor || props.selected ? `${props.borderColor || "#FF6D1D"}` : 'white'};
      color: ${props => props.isReversedColor || props.selected? 'white' : `${props.borderColor || "#FF6D1D"}`};
  }

`;


export const DataTable = styled.table`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 6px;
  border-collapse: collapse;
  border-radius: 6px; 
  overflow: hidden;
  th{
    text-align: center;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 6px solid white;
  }
  td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family:'SF Pro Text';
    border: 2px solid white;
  }
`;



export const StyledConciseTableContainer = styled.div`
  && {
    position: relative;
    max-height: ${(props) =>
      props.length
        ? `${props.length * 70}px`
        : `${window.innerHeight - 240}px`};
    //overflow-y: auto;
    overflow: auto;
    padding-right: ${(props) => props.paddingRight || "16px"};
    width: ${(props) => (props.width ? `${props.width}` : "")};
    height: ${(props) => (props.height ? `${props.height}` : "auto")};
  }

  &&::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

export const StyledFileName = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #b8b8b8;
  margin-top: 1px;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  margin-left: 12px;
  margin-right: 20px;
`;

export const StyledTooltipButton = styled(IconButton)`
  && {
    background-color: none;
    width: ${(props) => (props.isMobile ? "46%" : "49%")};
    margin: 0px;
    padding: 0px;
    &:hover {
      background-color: none;
    }
  }
`;

export const DetailArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
`;

export const ButtonPlacement = styled.div`
  width: ${(props) => (props.isMobile ? "46%" : "49%")};
`;

export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1440px;
  ${(props) => (props.isMobile ? "" : "align-content: center;")}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) =>
    props.isMobile ? "padding: 70px 0 0 0;" : "padding: 70px 70px 0px 70px;"}
  background-color: ${COLORS.backgroundGrey};
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "75%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;

export const DetailedFormContainer = styled(FormContainer)`
  && {
    width: ${(props) =>
      props.isMobile && !props.noWidthChange ? "90%" : "100%"};
  }
`;
export const SideContainer = styled.div`
  width: ${(props) => (props.isMobile ? "90%" : "25%")};
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "18px" : "28px")};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledSpan = styled.span`
  color: ${COLORS.primaryOrange};
`;

export const MediumText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "14px" : "18px")};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    color: ${(props) => (props.isMobile ? `${COLORS.textGrey}` : "black")};
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 6px;
  }
`;

export const GreySmallText = styled(SmallText)`
  && {
    color: ${COLORS.textGrey};
    font-weight: 400;
  }
`;

export const TinyText = styled(SmallText)`
  && {
    font-size: 12px;
    font-weight: 400;
    color: black;
  }
`;

export const MediumTinyText = styled(SmallText)`
  && {
    font-size: ${(props) => props.fontSize || "13px"};
    color: black;
  }
`;

export const PlaceholderText = styled(SmallText)`
  && {
    color: ${COLORS.textGrey};
    font-weight: 400;
    text-align: center;
    ${(props) =>
      props.hasBorderTop
        ? "border-top: 2px solid #EBE9F1; padding-top: 8px;"
        : ""}
  }
`;

export const TooltipText = styled(SmallText)`
  && {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
  }
`;

export const RegularText = styled(SmallText)`
  && {
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-bottom: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? "14px" : "32px")};
  margin-right: ${(props) => (props.isMobile ? "" : "70px")};
  // ${(props) =>
    props.isMobile && props.marginTop ? `margin-top: ${props.marginTop};` : ""}
`;

export const StyledArea = styled.div`
  width: 100%;
  display: flex;
  ${(props) => (props.isMobile ? "flex-wrap: wrap;" : "")}
  justify-content: ${(props) =>
    props.isMobile ? "space-around" : "space-between"};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const StyledBox = styled.div`
  width: ${(props) => props.boxWidth || "100%"};
  ${(props) =>
    props.hasData
      ? ""
      : `border-radius: ${props.borderRadius || `20px`}; margin-bottom: 12px;`}
  ${(props) =>
    props.hasData ? "" : "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
  height: ${(props) => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  ${(props) => (props.isMobile ? "align-items: center;" : "")}
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) => (props.hasData ? "" : "justify-content: center;")}
  ${(props) => (props.noBackground ? "" : "background-color: white;")}
`;

export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width || "100%"};
`;
export const FooterArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 33px;
  justify-content: flex-end;
  width: ${(props) => props.width || "100%"};
`;
export const StyledHeaderDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  //justify-content: space-between;
  margin-top: 16px;
`;
export const StyledHeading = styled.p`
  display: inline;
  color: #c4c4c4;
  font-size: 10px;
  margin-left: 16px;
`;
export const StyledProjectInfo = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-left: 16px;

  &:first-letter {
    text-transform: uppercase;
  }
`;
export const StyledTypography = styled(Typography)`
  && {
    font-size: 24px;
    font-family: SF Pro Text;
    color: #5e5873;
    font-weight: bold;
    margin-top: 30px;
    width: 16.67%;
  }
`;

export const StyledDocumentContainer = styled.div`
  ${(props) => (props.isMobile ? "margin-top: 16px;" : "")}
`;
export const MobileColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileColumnText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => props.fontSize || "13px"};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || "500"} !important;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.columnGreyText};
    width: 100%;
  }
`;

export const ScrollableDiv = styled.div`
  && {
    max-height: ${window.innerHeight - 480}px;
    overflow: auto;
  }
  &&::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  StyledButtonArea,
  StyledContainerInside,
  StyledContainerTitle,
  StyledHeaderContent,
  StyledSubHeaderContent,
} from "./styles";
import { FunctionalButton } from "components/functionalButton";
import { ExportIcon } from "assets/common/ExportIcon";
import { ImportIcon } from "assets/common/ImportIcon";
import { AddPaymentProgressContractorPopup } from "components/addPaymentProgressContractorPopup";
import { AddChangeOrderContractorPopup } from "components/addChangeOrderContractorPopup";
import { getWorkInProgress } from "api/work_in_progress";
import { postImportWipCSVData } from "api/external_data.js";
import * as XLSX from "xlsx";
import { AlertMessage } from "./alertMessage";
import { SuccessPopup } from "pages/contractorDashboard/profileSetup/SuccessPopup.js";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";
import { clientProjectsWipSelector } from "data/selectors/underwriters";

export const PageHeader = ({
  subTitle,
  hasButton,
  handleButton,
  marginBottom,
  companyName,
  project,
  data,
  companyId,
  projectStatus,
}) => {
  const [
    openPaymentProgressContractorPopup,
    setOpenPaymentProgressContractorPopup,
  ] = useState(false);
  const [openChangeOrderContractorPopup, setOpenChangeOrderContractorPopup] =
    useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [paymentProgressContractor, setPaymentProgressContractor] = useState(
    {}
  );
  const [changeOrderContractor, setChangeOrderContractor] = useState({});
  const [changeOrderId, setChangeOrderId] = useState(0);
  const [ppcId, setPpcId] = useState(0);
  const [clientProjectsData, setClientProjectsData] = useState([]);
  const [contractCompletionDate, setContractCompletionDate] = useState("");
  const dispatch = useDispatch();
  const clientProjects = useSelector(clientProjectsWipSelector);

  useEffect(() => {
    if (clientProjects) {
      setClientProjectsData(clientProjects);
    }
  }, [clientProjects]);

  const handlePaymentProgressContractorAdd = () => {
    setOpenPaymentProgressContractorPopup(true);

    getWorkInProgress(data, onSuccessPaymentProgressContractorAdd, () => {});
  };

  const onSuccessPaymentProgressContractorAdd = (res) => {
    setPaymentProgressContractor(res);
    setPpcId(res?.new_ppc_id);
    setOpenPaymentProgressContractorPopup(true);
  };

  const handleChangeOrderContractorAdd = () => {
    getWorkInProgress(data, onSuccessChangeOrderContractorAdd, () => {});
    // setOpenChangeOrderContractorPopup(true);
  };

  const onSuccessChangeOrderContractorAdd = (res) => {
    setChangeOrderId(res?.new_change_order_id);
    setContractCompletionDate(res?.contract_completion_date);
    setChangeOrderContractor(res);
    setOpenChangeOrderContractorPopup(true);
  };

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [notCorrectFormat, setNotCorrectFormat] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openFailurePopup, setOpenFailurePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  useEffect(() => {
    if (file) {
      convertAndUpload(file);
    }
  }, [file]);

  const convertAndUpload = (file) => {
    const fileReader = new FileReader();
    const fileName = file.name;

    if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      fileReader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        uploadCsvData(fileName, csv);
      };
      fileReader.readAsArrayBuffer(file);
    } else if (fileName.endsWith(".csv")) {
      fileReader.onload = (event) => {
        const text = event.target.result;
        uploadCsvData(fileName, text);
      };
      fileReader.readAsText(file);
    } else {
      setNotCorrectFormat(true);
    }
  };

  const uploadCsvData = (fileName, csvData) => {
    const data = {
      account_id: companyId,
      company_name: companyName,
      company_id: companyId,
      csvData: csvData,
      fileName: fileName,
    };
    postImportWipCSVData(data, onSuccessImportCSVData, onFailureImportCSVData);
  };

  const onSuccessImportCSVData = (res) => {
    setOpenSuccessPopup(true);
    dispatch(
      getClientProjectsInfoForWip({ account_id: companyId }, "underway")
    );
  };

  const onFailureImportCSVData = (res) => {
    setOpenFailurePopup(true);
    setErrorMessage(res.data.message);
  };

  const exportToCSV = () => {
    const headers = [
      "Project Name",
      "Project Number",
      "Estimated Revenue",
      "Original Estimated Cost of Revenue",
      "Billings to Date",
      "Cost Incurred to Date",
      "Cost to Complete",
      "Holdback/Retainage to Date",
      "Percent Completed",
    ];

    
    const rows = clientProjectsData.map((item) => {     
      const cost_to_date = item.wip_data[0].estimated_cost_of_revenue - item.wip_data[0].ppc_data.cost_incurred_to_date || 0
      return [
        item.project_name.replace(/,/g, ""),
        item.project_number,
        item.value,
        item.original_estimated_cost_of_revenue,
        item.wip_data[0].ppc_data.billings_to_date || 0,
        item.wip_data[0].ppc_data.cost_incurred_to_date || 0,
        cost_to_date,
        item.wip_data[0].ppc_data.holdback_or_retainage_to_date || 0,
        item.percent_completed,
      ];
    });

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "export-wip-data.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <StyledContainerTitle>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {companyName}
        </StyledHeaderContent>
        {subTitle && (
          <StyledSubHeaderContent>{subTitle}</StyledSubHeaderContent>
        )}
      </StyledContainerInside>
      {hasButton && !project && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="IMPORT"
              handleButton={() => fileInputRef.current.click()}
              width="200px"
              height="48px"
              isReversedColor={true}
              frontIcon={true}
              disabled={false}
              icon={<ImportIcon marginRight="16px" />}
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".xls,.csv,.xlsx,application/vnd.ms-excel,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onClick={(e) => {
                e.target.value = null;
                fileInputRef.current.click();
              }}
              onChange={handleFileUpload}
            />

            <FunctionalButton
              buttonTitle="EXPORT"
              handleButton={exportToCSV}
              width="200px"
              height="48px"
              marginRight="0"
              isReversedColor={true}
              frontIcon={true}
              disabled={false}
              icon={<ExportIcon marginRight="16px" />}
            />
          </StyledButtonArea>
        </>
      )}
      {hasButton && project && projectStatus !== "Completed" && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="PROGRESS PAYMENT "
              handleButton={handlePaymentProgressContractorAdd}
              width="215px"
              height="48px"
              fontSize="16px"
              isReversedColor={true}
            />
            <FunctionalButton
              buttonTitle="CHANGE ORDER"
              handleButton={handleChangeOrderContractorAdd}
              width="170px"
              height="48px"
              isReversedColor={true}
            />
          </StyledButtonArea>
        </>
      )}

      <AddPaymentProgressContractorPopup
        setOpenDialog={setOpenPaymentProgressContractorPopup}
        openDialog={openPaymentProgressContractorPopup}
        data={paymentProgressContractor}
        setData={setPaymentProgressContractor}
        projectData={data}
        ppcId={ppcId}
        isMobile={isMobile}
        companyId={companyId}
      />

      <AddChangeOrderContractorPopup
        setOpenDialog={setOpenChangeOrderContractorPopup}
        openDialog={openChangeOrderContractorPopup}
        data={changeOrderContractor}
        setData={setChangeOrderContractor}
        projectData={data}
        changeOrderId={changeOrderId}
        oldContractCompletionDate={contractCompletionDate}
        isMobile={isMobile}
        companyId={companyId}
      />

      <AlertMessage
        isMobile={isMobile}
        openPopup={notCorrectFormat}
        setOpenPopup={setNotCorrectFormat}
        description="You can only import .xls or .xlsx or .csv format files."
        isFormatIssue={false}
      />

      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
        descriptionText={"Your Work in Progress(WIP) file has been imported."}
      />

      <AlertMessage
        isMobile={isMobile}
        openPopup={openFailurePopup}
        setOpenPopup={setOpenFailurePopup}
        description={errorMessage}
        isFormatIssue={true}
      />
    </StyledContainerTitle>
  );
};
